* {
   box-sizing: border-box;
}
body,
h1,
h2,
h3,
p {
   margin: 0;
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   font-weight: 200;
   
}
body {
   /* background: #e2ebeb; */
   background: #e7eff4;

}
h1 {
   margin-bottom: .4em;
}
p {
   font-size: 1em;
}
.link {
   text-decoration: none;
}
.App {
   display: flex;
   flex-direction: column;
   min-height: 100vh;
}
.headercontainer {
   display: flex;
   flex-direction: column;
}
header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   background: #e7eff4;
   z-index: 100000;
   /*
   background-image: url('/src/img/piano.jpeg');
   background-repeat: no-repeat;
   background-size: auto;
   background-position: 0px -250px;
   background-blend-mode:lighten;
   border: 1px solid magenta; */
}
.alert-header {
   display: flex;
   flex-direction: row;
   width: 100vw;
   margin-top: 1rem;
   justify-content: space-around;
   color:rgb(205, 91, 29);
   background: #e7eff4;
   z-index: 100000;
}

.site-head {
   display: flex;
   margin-bottom: 1em;
   z-index: 100001;
}
.logo-left {
   /* margin-left: 32px; */
   height: 70px;
   margin: 6px 0 0 32px;
}

.title {
   margin: 0 0 -10px 0;
   font-family: Josefin Sans;
   font-size: 4em;
   align-self: flex-end;
   /* font-weight: 200; */
   /* letter-spacing: 0em; */
}
.sub-header {
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   font-style: normal;
   font-weight: 200;
   letter-spacing: 0.15em;
   font-size: 1.5em;
   display: flex;
   justify-content: center;
   padding: 0 4px;
   margin: 0 0 1em 0;
   background: #2A7473;
   color: #DFEAEA;
   z-index: 100001;
}
.login-button {
   align-self: center;
   /* width: 100px;
   height: 32px; */
   margin-right: 4em;
   background: #2A7473;
   color: #DFEAEA;
   border: 1px solid #2A7473;
   padding: .3em 1em;
   border-radius: 4px;
   font-size: 1.5em;
   letter-spacing: 1px;
}
.login-button:hover,
.login-button:focus {
   background: #f0efef;
   color: #2A7473;
   cursor: pointer;
}
.login-button:active {
   background-color: #94d1d1;
}

.container {
   display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   padding: 0 1.5em;
   margin-bottom: 3em;
   /* max-width: 1920px; */
   /* height: 100%; */
   /* border: 2px solid magenta; */
}
.post, .singlepost {

   border: 2px solid #95b5b5;
   margin: 10px;
   padding: 20px;
   font-size: 1.1em;
   transition: all 100ms;
   color: #205c5b;
   background: #f6f4e8;
   background: #ffffff;
   box-shadow: 2px 2px 2px #bbd9d9;
   position: relative;
}
.singlepost {
   width: 90%;
}

.post:hover,
.post:focus {
   border-width: 4px;
   cursor: pointer;
   padding: 18px;
}
.post:active {
   border-width: 8px;
   padding: 14px;
}
.post-main {
   width: 100%;
}
hr {
   border-top: 2px;
   border-color: #205c5b;
}
.post-header {
   font-size: 1.5em;
   font-weight: 400;
}
.post-body {
   font-size: 1em;
   color: rgb(30, 30, 30);
}
.post-body p {
   margin-bottom: .7em;
}
.post-body img {
   max-width: 100%;
   height: auto;
}
.singlepost {
   /* display: flex; */
   overflow-x: auto;
}
.singlepost a {
   color: rgb(30, 30, 30);
}

@media (max-width:700px) {
   header {
      flex-direction: column;
      align-items: center;
   }
   .login-button {
      margin: 2em 0;
   }
   .sub-header {
      font-size: 1em;
      text-align: center;
      padding: .5em;
   }
   .container {
      padding: 0 0.1em;
   }
   .logo-left {
      margin-left: 12px;
      height: 60px;
   }
   .footer {
      font-size: .9em !important;
   }
}
@media (max-width: 350px) {
   .site-head {
      flex-direction: column;
   }
   .footer {
      font-size: .7em !important;
   }
}