#pricetable {
   width: 80%;
}
#pricetable td {
   text-align: right;
}
#pricetable thead {
   font-weight: 600;
}
#pricetable tbody td {
   padding-right: 12px;
}
p {
   margin-bottom: 0.7em;
}
ul {
   margin: 0;
}
li {
   font-size: 0.9em;
   margin-bottom: 0.6em;
}
strong {
   font-weight: 600;
}
/* .post-main {
   position: relative;
} */
.single-post a {
   text-decoration: underline !important;
   color: rgb(30, 30, 30) !important;
}
