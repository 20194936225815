
.clock-container {
  position: relative;
  width: 45vh;
  height: 45vh;
  margin: 12px auto;
  background: rgba(128, 128, 128, 0.04);
  border-radius: 50%;
  z-index: 1000;
}

.clock-dial {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.clock-hand {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: 50% 50%; /* Rotate around the center */
  filter: drop-shadow(-4px -4px 2px rgba(0, 0, 0, 0.15));
}

.date-day {
  position: absolute;
  width: 100%;
  top: 18%;
  color: #666;
  font-size: 2vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.date-weekday {
  position: absolute;
  width: 100%;
  bottom: 22%;
  color: #666;
  font-size: 1.6vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
