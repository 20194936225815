p {
   margin-bottom: 0.5em;
}
.post {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 250px;
   max-width: 350px;
   overflow: auto;
   position: relative;
}
.post a {
   color: rgb(30, 30, 30) !important;
   text-decoration: none;
}
