.footer {
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-around;
   background: #2A7473;
   color: #DFEAEA;
   padding: 8px;
   margin: auto 8px 0 0;
   font-size: 1.1em;
   position: fixed;
   bottom: 0;
   width: 100%;
}
.footer a {
   text-decoration: none;
   color: #DFEAEA;   
}
.footer div {
   margin: 0 8px;
}