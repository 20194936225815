.backbutton {
   position: absolute;
   height: 1.5em;
   right: 20px;
   bottom: 10px;
   background: #2A7473;
   color: #DFEAEA;
   border: 1px solid #2A7473;
   padding: .1em .6em;
   border-radius: 4px;
   font-size: 0.8em;
   letter-spacing: 1px;
}
.backbutton:hover,
.backbutton:focus {
   background: #f0efef;
   color: #2A7473;
   cursor: pointer;
}
.backbutton:active {
   background-color: #94d1d1;
}
